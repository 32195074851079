import type {LendiQuery, MeetingOrVerificationCustomerInterest, Utms} from '~/models/UTMs/UTMs';
import WindowUtils from '~/service/windowUtils';
import type {SimpleOffer} from '~/models/simulation/simpleSimulation/SimpleOffer';
import {CustomerInterest} from '~/enums/Interest';
import {CreditWorthinessInput} from '~/models/simulation/CreditWorthiness';
import {cleanFromFalsy} from "~/service/Utils";
import {useQuery} from "~/composables/useQuery";
import {openPathWithQueryInNewTab} from "~/service/usePageNavigation";
import type {RoutesNamesList} from "~/.nuxt/typed-router";

type goToConfig = {
  utms: Partial<Utms>,
  openInNewTab?: boolean;
}
type goToTerminKonsultacjiOfertaConfig ={
  offer: SimpleOffer;
  loanAmount: number;
  loanPeriod: number;
  currentRouteName: string;
  uuid?: number;
  customerInterest: MeetingOrVerificationCustomerInterest;
} & goToConfig

type goToKalkulatorZdolnosciKredytowejTerminKonsultacjiZdolnoscConfig= {
  creditWorthinessInput: CreditWorthinessInput,
  creditWorthiness: number,
} & goToConfig


type PotentialTerminKonsultacjiOfertaRoutes = PickUnion<RoutesNamesList, 'kredyty-hipoteczne-termin-konsultacji-oferta' | 'kredyty-gotowkowe-termin-konsultacji-oferta' |
    'kalkulator-zdolnosci-kredytowej-termin-konsultacji-oferta'>

export default function useGoTo() {
  /** @WARNING if openInNewTab = true, You have to provide targetRoutePath!!!
   * targetRouteWithPassedUuidAndOwnerFromQuery = withMaybePassedUuidAndOwnerFromQuery(targetRoute)*/
  const goTo = (targetRoute: any, openInNewTab?: boolean, targetRoutePath?: string) => {
    const targetRouteWithPassedUuidAndOwnerFromQuery = withMaybePassedUuidAndOwnerFromQuery(targetRoute);
    if (openInNewTab) {
      openPathWithQueryInNewTab(targetRoutePath, targetRouteWithPassedUuidAndOwnerFromQuery.query);
      return;
    }
    navigateTo(targetRouteWithPassedUuidAndOwnerFromQuery);
  };

  const withMaybePassedUuidAndOwnerFromQuery = (route: any) =>{
    const {query,} = useQuery<LendiQuery>();
    if (query.uuid) {
      route.query.uuid = query.uuid;
    }
    if (!query.uuid && query.utm_owner){
      route.query.utm_owner = query.utm_owner;
    }
    return route;
  };

  const goToTerminKonsultacjiOferta = (goToTerminKonsultacjiOfertaConfig: goToTerminKonsultacjiOfertaConfig) => {
    const {
      utms,
      offer,
      loanAmount,
      loanPeriod,
      openInNewTab,
      currentRouteName,
      customerInterest,
      uuid,
    } = goToTerminKonsultacjiOfertaConfig;

    const updatedQuery: Partial<LendiQuery> = cleanFromFalsy<Partial<LendiQuery>>({
      ...utms,
      bank: offer.bankSymbol,
      rrso: offer.rrso,
      installment: offer.installment,
      loanAmount,
      loanPeriod,
      customerInterest,
      uuid,
    });

    const targetRoutePath = `${currentRouteName}/termin-konsultacji/oferta`;
    const targetRoute = withMaybePassedUuidAndOwnerFromQuery({
      name: `${currentRouteName}-termin-konsultacji-oferta` as PotentialTerminKonsultacjiOfertaRoutes,
      query: updatedQuery,
    });

    goTo(targetRoute, openInNewTab, targetRoutePath);
  };

  const goToKalkulatorZdolnosciKredytowejTerminKonsultacjiZdolnosc =
      (goToConfig: goToKalkulatorZdolnosciKredytowejTerminKonsultacjiZdolnoscConfig) => {
        const {
          utms,
          creditWorthinessInput,
          creditWorthiness,
          openInNewTab,
        } = goToConfig;

    const updatedQuery: Partial<LendiQuery> = {
      ...utms,
      ...creditWorthinessInput,
      creditWorthiness,
    };

    const targetRoutePath = 'kalkulator-zdolnosci-kredytowej/termin-konsultacji/zdolnosc' as const;
    const targetRoute = withMaybePassedUuidAndOwnerFromQuery({
          name: 'kalkulator-zdolnosci-kredytowej-termin-konsultacji-zdolnosc',
          query: updatedQuery,});

    goTo(targetRoute, openInNewTab, targetRoutePath);
  };

  const getGoToProps = (path: string, query: Record<string, any> = {}) =>
    path.startsWith('http') || path.startsWith('#')
    ? {href: path,}
    : {to: {path: path, query,},};

  return {
    goTo,
    goToTerminKonsultacjiOferta,
    goToKalkulatorZdolnosciKredytowejTerminKonsultacjiZdolnosc,
    getGoToProps,
  };
}
